<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">关键字</div>
					<div class="inputs_box">
						<el-input clearable="" type="text" placeholder="请输入名称" v-model="forms.keyword" />
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">记录类型</div>
					<div class="inputs_box">
						<el-select clearable="" v-model="type_select" placeholder="请选择">
							<el-option v-for="item in type_list" :key="item.title" :label="item.title" :value="item.title">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">时间筛选</div>
					<div class="inputs_box">
						<!-- 	<el-date-picker clearable="" v-model="times" value-format="yyyy-MM-dd" type="datetime" placeholder="请选择">
						</el-date-picker> -->

						<el-date-picker :picker-options="pickerOptions" clearable v-model="date_range" format="yyyy-MM-dd"
							value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>

					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search()">搜索</div>
				</div>
			</div>
		</div>


		<div class="bom_box">

			<div class="account-titlebox">
				<div class="title">{{page_title}}</div>
				<!-- <div class="two-toggle">
					<div class="tabs">
						<span v-for="(item,index) in guanli" :key="index" :class="{active:activeIndex == item.status}"
							@click="toStatus(item)">{{item.title}}</span>
					</div>
					<div class="end-end">
						<span class="all">全部标记已读</span>
						<span class="clear">清空</span>
					</div>
				</div> -->

			</div>

			<div class="bom_box_list" v-loading="loading">
				<div class="list-list">
					<div class="item-item" v-for="(item,index) in list" :key="index">
						<div class="left"> {{item.title}}</div>
						<div class="constst">修改人：{{item.admin_name}}</div>
						<div class="right">{{item.created_at}}</div>
					</div>
				</div>

				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	export default {
		components: {
			page,
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '历史记录', //页面名称  来之路由
				check_list: [], //选中的
				activeIndex: 0, //默认选中
				//提交数据
				guanli: [{
						title: '未读',
						status: 0,
					},
					{
						title: '全部',
						status: 1,
					},
				],
				forms: {
					keyword: '',
					s_time: '',
					e_time: '',
					is_yg:1,
				},
				list: [

				],

				type_list: [{
						title: '员工信息',
						value: '1'
					},
					{
						title: '通讯管理',
						value: '2'
					},
					{
						title: '合同管理',
						value: '3'
					}, {
						title: '员工调动',
						value: '4'
					}, {
						title: '组织架构',
						value: '5'
					},
				],
				type_select: '',
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围


				//
				date_range: [],
				pickerOptions: {
					disabledDate(time) {
						// console.log('time', time)
						return (time.getTime()) > new Date().getTime();
					}
				},
				//
			};
		},
		computed: {},
		watch: {
			date_range(value) {
				this.$log('日期范围', value)

					
				this.forms.s_time = value[0] || ''
				this.forms.e_time = value[1] || ''
			}
		},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},
		methods: {
			toStatus(item) {
				this.activeIndex = item.status
			},
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("getAdminLogList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
					action: this.type_select
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},

		},



	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}


	}



	.account-titlebox {
		// display: flex;
		// justify-content: space-between;
		// align-items: center;

		.title {
			color: #333333;
			font-size: 14px;
			font-weight: bold;
			padding: 12px 17px;
			border-bottom: 1px solid #EDF0F6;
		}

		.two-toggle {
			padding: 0 21px;

			.tabs {
				display: flex;
				border-bottom: 1px solid #D9D9D9;

				span {
					width: 100px;
					color: #333333;
					font-size: 16px;
					display: flex;
					cursor: pointer;
					padding: 22px 0px 16px 0px;
					position: relative;
					justify-content: center;
					align-items: center;
					margin-right: 10px;

					&.active {
						color: #3697FD;

						&::after {
							content: '';
							position: absolute;
							width: 100%;
							height: 2px;
							background: #0564ca;
							bottom: 0;
							left: 0;
						}
					}
				}
			}
		}


	}

	.two-toggle {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.list-list {
		.item-item {
			display: flex;
			padding: 17px 0;
			border-bottom: 1px solid #EDEDED;
			justify-content: space-between;

			.right {
				color: #949494;
				font-size: 12px;
			}
			.constst{
				font-size: 14px;
				color: #3E3E3E;
			}


			.left {
				width: 400px;
				display: flex;
				align-items: center;
				font-size: 14px;
				color: #3E3E3E;

				&::before {
					content: '';
					display: block;
					width: 5px;
					height: 5px;
					background: #E4E4E4;
					margin-right: 17px;
				}
			}
		}
	}

	.end-end {
		display: flex;

		span {
			height: 40px;
			border-radius: 4px 4px 4px 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 25px;
			cursor: pointer;
			font-size: 14px;

			&:hover {
				opacity: 0.854;
			}
		}

		.all {
			color: #fff;
			background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
		}

		.clear {
			background: #FFFFFF;
			border-radius: 2px 2px 2px 2px;
			border: 1px solid #EFEFEF;
			margin-left: 9px;

		}
	}


	.bom_box {
		background: #FFFFFF;

		.bom_btn_flex {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 51px;
			background: #FFFFFF;
			box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
			border-radius: 4px 4px 4px 4px;
			margin-top: 20px;
			padding: 0 20px;

			.lefts {
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.rights {
				display: flex;
				align-items: center;

				.btns {
					background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
					padding: 6px 13px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #FFFFFF;
					cursor: pointer;

					&:hover {
						background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

					}
				}

			}
		}

		.bom_box_list {
			width: 1640px;
			height: 670px;
			padding: 20px 20px 32px 20px;

			.pages {
				padding-top: 30px;
			}
		}

	}
</style>